<template>
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<mask
			id="mask0_229_6049"
			style="mask-type: alpha"
			maskUnits="userSpaceOnUse"
			x="0"
			y="0"
			width="20"
			height="20"
		>
			<rect width="20" height="20" fill="#D9D9D9" />
		</mask>
		<g mask="url(#mask0_229_6049)">
			<path
				d="M9.25 19V16.9583C7.56944 16.7639 6.16319 16.0972 5.03125 14.9583C3.89931 13.8194 3.23611 12.4167 3.04167 10.75H1V9.25H3.04167C3.23611 7.58333 3.89931 6.18056 5.03125 5.04167C6.16319 3.90278 7.56944 3.23611 9.25 3.04167V1H10.75V3.04167C12.4306 3.23611 13.8368 3.90278 14.9688 5.04167C16.1007 6.18056 16.7639 7.58333 16.9583 9.25H19V10.75H16.9583C16.7639 12.4167 16.1007 13.8194 14.9688 14.9583C13.8368 16.0972 12.4306 16.7639 10.75 16.9583V19H9.25ZM10 15.5C11.5278 15.5 12.8264 14.9653 13.8958 13.8958C14.9653 12.8264 15.5 11.5278 15.5 10C15.5 8.47222 14.9653 7.17361 13.8958 6.10417C12.8264 5.03472 11.5278 4.5 10 4.5C8.47222 4.5 7.17361 5.03472 6.10417 6.10417C5.03472 7.17361 4.5 8.47222 4.5 10C4.5 11.5278 5.03472 12.8264 6.10417 13.8958C7.17361 14.9653 8.47222 15.5 10 15.5ZM10 13C9.16667 13 8.45833 12.7083 7.875 12.125C7.29167 11.5417 7 10.8333 7 10C7 9.16667 7.29167 8.45833 7.875 7.875C8.45833 7.29167 9.16667 7 10 7C10.8333 7 11.5417 7.29167 12.125 7.875C12.7083 8.45833 13 9.16667 13 10C13 10.8333 12.7083 11.5417 12.125 12.125C11.5417 12.7083 10.8333 13 10 13ZM10 11.6458C10.4526 11.6458 10.8401 11.4847 11.1624 11.1624C11.4847 10.8401 11.6458 10.4526 11.6458 10C11.6458 9.54739 11.4847 9.15993 11.1624 8.83763C10.8401 8.51532 10.4526 8.35417 10 8.35417C9.5474 8.35417 9.15994 8.51532 8.83763 8.83763C8.51532 9.15993 8.35417 9.54739 8.35417 10C8.35417 10.4526 8.51532 10.8401 8.83763 11.1624C9.15994 11.4847 9.5474 11.6458 10 11.6458Z"
				fill="currentColor"
			/>
		</g>
	</svg>
</template>
